import React from "react";
import { Home } from "./Home";
import { Callback } from "./Callback";
import { useRoutes } from "hookrouter";

const routes = {
  '/': () => <Home />,
  '/callback': () => <Callback />
};

export function App() {
  const match = useRoutes(routes);
  return match? match : <Home />;
}
