import React, { useState } from "react";
import { Calendar, CalendarControls } from "react-yearly-calendar";
import "./react-yearly-calendar.css";
import moment from "moment";

export type YearlyCalendarItem = { date: Date; description: string };

export function YearlyCalendar(props: {
  id: string;
  events: YearlyCalendarItem[];
  holidays: YearlyCalendarItem[];
}) {
  const year=new Date().getFullYear();
  const minYear=new Date().getFullYear();
  const maxYear=new Date().getFullYear()+2;
  const firstDayOfWeek = 1;
  const [currentYear, setCurrentYear] = useState(year);
  const onPrevYear = () => {
    setCurrentYear(
      currentYear - 1 >= minYear ? currentYear - 1 : currentYear
    );
  };
  const onNextYear = () => {
    setCurrentYear(
      currentYear + 1 <= maxYear ? currentYear + 1 : currentYear
    );
  };
  const customClasses = (day: moment.Moment) => {
    if (props.events.some(x => day.isSame(x.date))) {
      return "event-day";
    } 
    else if (day.day() > 5 || day.day()===0) {
      return "weekend";
    }else if (props.holidays.some(x => day.isSame(x.date))) {
      return "holidays";
    }
    return "";
  };

  return (
    <div id={props.id} style={{ width: "1270px" }}>
      <CalendarControls
        year={currentYear}
        onPrevYear={onPrevYear}
        onNextYear={onNextYear}
      />
      <Calendar
        firstDay
        year={currentYear}
        customClasses={customClasses}
        firstDayOfWeek={firstDayOfWeek}
      />
    </div>
  );
}
