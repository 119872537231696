import auth0, { Auth0Error } from "auth0-js";
import { navigate } from "hookrouter";

let _id_token = null;
let _access_token = null;
let _expiresAt = 0;

export default class Auth {
  auth0: auth0.WebAuth;
  constructor() {
    const options: auth0.AuthOptions = {
      domain: "monthly-dev.au.auth0.com",
      clientID: "O1mLJRpwd6dTrd0bFynzEgT8FNF3D8ZF",
      redirectUri: "http://localhost:3000/callback",
      responseType: "token id_token",
      scope: "openid profile email"
    };
    this.auth0 = new auth0.WebAuth(options);
  }

  login = () => {
    this.auth0.authorize();
  };
  
  renewToken = (onLoggedIn:()=>void) => {
    this.auth0.checkSession({}, (err, result)=>{
        console.log("test");
        if(err){
            console.log(err);
        }else{
            this.saveSession(result);
            onLoggedIn();
        }
    });

    navigate("/");
  };

  logoff = () => {
      this.auth0.logout({
          clientID: "O1mLJRpwd6dTrd0bFynzEgT8FNF3D8ZF",
          returnTo: "http://localhost:3000"
      });
    this.removeSession();
    navigate("/", true);
  };

  handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.saveSession(authResult);
        navigate("/", true);
      } else if (err) {
        console.log(err);
        alert(err);
        navigate("/", true);
      }
    });
  };
  isAuthenticated() {
    return new Date().getTime() < _expiresAt;
  }
  saveSession(authResult: auth0.Auth0DecodedHash) {
    console.log(authResult);
    const expiresAt = (authResult.expiresIn ?? 0) * 1000 + new Date().getTime();
    _access_token = authResult.accessToken;
    _id_token = authResult.idToken;
    _expiresAt = expiresAt;
  }
  removeSession() {
    _access_token = null;
    _id_token = null;
    _expiresAt = 0;
  }
}
