import React, { useState, useEffect } from "react";
import Auth from "./auth/auth";
import { useQueryParams } from "hookrouter";

const test = {
  margin: "5px",
  padding: "2px"
};

const style = {
  loginButton: test
};

export function UserProfile() {
  const [queryParams] = useQueryParams();
  const { id_token } = queryParams;
  const [loggedIn, setLoggedIn] = useState(false);

  const auth = new Auth();
  useEffect(() => {
    auth.renewToken(() => setLoggedIn(true));
  });

  return <div></div>;
  //return (loggedIn ? <button style={style.loginButton} onClick={auth.logoff}>Log off</button> : <button style={style.loginButton} onClick={auth.login}>Log In</button>);
}
