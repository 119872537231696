import React, {useEffect } from "react";
import Auth from "./auth/auth";

export function Callback(props: any) {
  let auth = new Auth();
  //   var parsedHash = new URLSearchParams(
  //     window.location.hash.substr(1) // skip the first char (#)
  // );
  useEffect(() => auth.handleAuthentication());
  return <h1>Loading...</h1>;
}
