import axios from "axios";

export type CalendarEvent = { date: Date; description: string };

export type Holiday = {
  date: Date;
  name: string;
  locations?: ISCountryOLocation[];
};

export type ISCountryOLocation = {
  isoCode: string;
  name: string;
};
export type EventsViewModel = {
  Events: CalendarEvent[];
};

export function getCountries(): Promise<ISCountryOLocation[]> {
  return axios.get(
    `https://d1wntsoxu2.execute-api.ap-southeast-2.amazonaws.com/Prod/api/countries`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
}

export function getLocations(countryISO: string): Promise<ISCountryOLocation> {
  return axios.get(
    `https://d1wntsoxu2.execute-api.ap-southeast-2.amazonaws.com/Prod/api/countries/${countryISO}`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
}

export function getHolidays(countryISO: string, locationISO: string) {
  return axios.get(
    `https://d1wntsoxu2.execute-api.ap-southeast-2.amazonaws.com/Prod/api/holidays?countryISO=${countryISO}&locationISO=${locationISO}`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
}

export function getEvents(
  countryISO: string,
  locationISO: string,
  startDate: Date,
  endDate: Date,
  n: number,
  title: string,
  description: string,
  includeWeekends: boolean,
  includeHolidays: boolean
) {
  return axios.post(
    "https://fnrynps02j.execute-api.ap-southeast-2.amazonaws.com/dev/",
    {
      countryISO,
      locationISO,
      startDate,
      endDate,
      nthBusinessDay: n,
      title,
      description,
      outputFormat: "events",
      includeWeekends,
      includeHolidays
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
}

export function getCalendar(
  countryISO: string,
  locationISO: string,
  startDate: Date,
  endDate: Date,
  n: number,
  title: string,
  description: string
) {
  return axios.post(
    "https://fnrynps02j.execute-api.ap-southeast-2.amazonaws.com/dev/",
    {
      countryISO,
      locationISO,
      startDate,
      endDate,
      nthBusinessDay: n,
      title,
      description,
      outputFormat: "ics"
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
}
