import React, { useState, useEffect } from "react";
import moment from "moment";
import { FirstLastNthPicker } from "./FirstLastNthPicker";
import { YearlyCalendar } from "./YearlyCalendar";
import {
  getEvents,
  Holiday,
  CalendarEvent,
  getHolidays,
  ISCountryOLocation,
  getCountries,
  getLocations,
  getCalendar
} from "./apiProxy";
import { UserProfile } from "./UserProfile";
import { saveAs } from "file-saver";

const test = {
  margin: "5px 0px",
  padding: "2px"
};

const style = {
  body: test,
  label: {
    flexBasis: "160px" as const
  },
  row: {
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "baseline" as const,
    maxWidth: "600px",
    ...test
  },
  textInput: {
    width: "275px",
    ...test
  },
  dateInput: {
    margin: "2px 8px 2px 0px"
  },
  calendarInput: {
    margin: "2px 8px 2px 0px",
    width: "100px",
    maxWidth: "200px"
  },

  button: {
    margin: "30px 28px 20px 5px",
    width: "150px"
  }
};

function saveAsFile(fileName: string, text: string) {
  var blob = new Blob([text], { type: "text/plain;charset=utf-8" });
  saveAs(blob, fileName);
}

export function Home(props: any) {
  const [title, setTitle] = useState("Monthly inventory check");
  const [countries, setCountries] = useState<ISCountryOLocation[]>([]);
  const [country, setCountry] = useState("US");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear() + 1, 11, 31)
  ); //default to the end of next year
  const [nthBusinessDay, setNthBusinessDay] = useState(1);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [isoLocation, setISOLocation] = useState("national");
  const [includeWeekends, setIncludeWeekends] = useState(false);
  const [includeHolidays, setIncludeHolidays] = useState(false);
  const [isoLocations, setISOLocations] = useState<ISCountryOLocation[]>([
    { isoCode: "national", name: "national" }
  ]);

  useEffect(() => {
    getCountries()
      .then((response: any) => {
        let countries: ISCountryOLocation[] = response.data;
        setCountries(countries);
      })
      .catch(function(error: any) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getLocations(country)
      .then((response: any) => {
        let locations: ISCountryOLocation[] = response.data;
        locations = [{ isoCode: "national", name: "national" }, ...locations];
        setISOLocations(locations);
      })
      .catch(function(error: any) {
        console.log(error);
      });
  }, [country]);

  useEffect(() => {
    getHolidays(country, isoLocation)
      .then((response: any) => {
        let holidays: Holiday[] = response.data;

        setHolidays(holidays);
      })
      .catch(function(error: any) {
        console.log(error);
      });
  }, [country, isoLocation]);

  useEffect(() => {
    getEvents(
      country,
      isoLocation,
      startDate,
      endDate,
      nthBusinessDay,
      title,
      title,
      includeWeekends,
      includeHolidays
    )
      .then((response: any) => {
        setEvents(response.data.Events);
      })
      .catch(function(error: any) {
        console.log(error);
      });
  }, [country, isoLocation, startDate, endDate, nthBusinessDay, title, includeWeekends, includeHolidays]);

  const maxEndDate = new Date(new Date().getFullYear() + 2, 11, 31); // end of the year after next year
  let YearlyCalendarHolidays = holidays.map(x => {
    return { date: x.date, description: x.name };
  });
  let locationOptions = isoLocations.map(x => (
    <option value={x.isoCode} selected={x.isoCode===isoLocation}>{x.name}</option>
  ));
  let countriesOptions = countries.map(x => (
    <option value={x.isoCode} selected={x.isoCode===country}>{x.name}</option>
  ));

  const onExportClicked = () =>
    getCalendar(
      country,
      isoLocation,
      startDate,
      endDate,
      nthBusinessDay,
      title,
      title
    )
      .then(resonse => {
        saveAsFile("bizScheduler.ics", resonse.data);
      })
      .catch(err => {
        console.log(err);
      });

  return (
    <div style={style.body}>
      <div style={style.row}>
        <h3>Create monthly events on business days</h3>
      </div>
      <div style={style.row}>
        <UserProfile />
      </div>
      <div style={style.row}>
        <label style={style.label}>{"Event title: "}</label>
        <input
          type="text"
          name="title"
          value={title}
          onChange={event => setTitle(event.target.value)}
          style={style.textInput}
        ></input>
      </div>
      <div style={style.row}>
        <label style={style.label}>{"Repeat on: "}</label>
        <FirstLastNthPicker onSelectionChanged={setNthBusinessDay}/>
        <label style={{ margin: "0px 5px" }}>{"business day each month"}</label>
      </div>
      <div style={style.row}>
        <label style={style.label}>{"Starting from: "}</label>
        <input
          style={style.dateInput}
          type="date"
          name="start"
          min={moment(new Date()).format("YYYY-MM-DD")}
          max={moment(maxEndDate).format("YYYY-MM-DD")}
          value={moment(startDate).format("YYYY-MM-DD")}
          onChange={event =>
            setStartDate(
              event.target.valueAsDate ? event.target.valueAsDate : new Date()
            )
          }
        ></input>
        {" to: "}
        <input
          style={style.dateInput}
          type="date"
          name="end"
          min={moment(new Date()).format("YYYY-MM-DD")}
          max={moment(maxEndDate).format("YYYY-MM-DD")}
          value={moment(endDate).format("YYYY-MM-DD")}
          onChange={event =>
            setEndDate(
              event.target.valueAsDate ? event.target.valueAsDate : maxEndDate
            )
          }
        ></input>
      </div>
      <div style={style.row}>
        <label style={style.label}>{"Avoid weekends:"}</label>
        <input type="checkbox" checked={!includeWeekends} onChange={arg=>setIncludeWeekends(!includeWeekends)}></input>
      </div>
      <div style={style.row}>
        <label style={style.label}>{"Avoid holidays:"}</label>
        <input type="checkbox" checked={!includeHolidays} onChange={arg=>setIncludeHolidays(!includeHolidays)}></input>
        {"in Country:"}
        <select
          onChange={event => setCountry(event.target.value)}
          style={style.calendarInput}
        >
          {countriesOptions}
        </select>
        {" City/State:"}
        <select
          name="isoLocation"
          value={isoLocation}
          onChange={event => setISOLocation(event.target.value)}
          style={style.calendarInput}
        >
          {locationOptions}
        </select>
      </div>

      <div style={style.row}></div>
      <YearlyCalendar
        id="calendar"
        events={events}
        holidays={YearlyCalendarHolidays}
      />
      <button onClick={onExportClicked} style={style.button}>
        Export as ICS File
      </button>
      <div>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfA44vh1aAcaeiEhyynRo6eS0pc3nxiThi4liKbV_69j8SuGA/viewform?usp=sf_link">
          Want to leave a comment or request?
        </a>
      </div>
    </div>
  );
}
